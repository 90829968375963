import { useState } from "react";
import axios from "axios";
import { FormControl, FormLabel, Switch, Stack, Button, Input, Box, Heading } from "@chakra-ui/react";
import { CONFIG } from "../utils/constants";
import React from "react";

export default function EmployeeStatusForm({ onStatusAdd }: any) {
    const [name, setName] = useState("");
    const [isActive, setIsActive] = useState(false);

    const handleFormSubmit = async (event: any) => {
        event.preventDefault();
        const bodyObj = {
            name,
            is_active: isActive
        };
        try {
            await axios.post(`${CONFIG.API_URL}/employee_statuses/`, bodyObj);
            // reset post obj
            setName('');
            setIsActive(false);
            onStatusAdd();
        } catch (error) {
            console.log("Save Error: ", error);
        }
    }

    return (
        <Box
            boxShadow={'2xl'}
            my={4}
            padding={4}
            borderWidth="1px"
            borderRadius="lg"
            bg="white">
            <Heading fontSize="lg" mb={4}>Add New Employee Status</Heading>
            <form onSubmit={handleFormSubmit}>
                <Stack>
                    <FormControl isRequired>
                        <FormLabel>Employee Status</FormLabel>
                        <Input type="text" name="name" value={name} placeholder="Status name" onChange={(e) => setName(e.target.value)}></Input>
                    </FormControl>
                    <FormControl>
                        <FormLabel htmlFor="is_active">Is Active</FormLabel>
                        <Switch name="is_active" id="is_active" isChecked={isActive} onChange={(e) => setIsActive(e.target.checked)} />
                    </FormControl>
                    <Button mt={4} type="submit" colorScheme="blue">Submit</Button>
                </Stack>
            </form>
        </Box>
    );
}