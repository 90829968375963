import React from 'react';
import {
    Box,
    useColorModeValue,
    Heading,
    Image
} from '@chakra-ui/react'
import { CONFIG } from '../utils/constants';


export default function ServiceCard({ serviceInfo }: any) {
    const image_url = `${CONFIG.API_URL}/${serviceInfo.icon_url}`;
    return (
        <Box
            maxW={'320px'}
            w={'full'}
            bg={useColorModeValue('white', 'gray.900')}
            boxShadow={'2xl'}
            rounded={'lg'}
            textAlign={'center'}>
            <Box p={6}>
                <Image src={image_url} maxW="230px" height="200px" marginLeft="auto" marginRight="auto" />
                <Heading my={5} fontSize={'2xl'} fontFamily={'body'}>
                    {serviceInfo.name}
                </Heading>
            </Box>
        </Box>
    )
}