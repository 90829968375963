import React from 'react';
import { Box, Text, Image } from '@chakra-ui/react';

const NoData = () => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            p={8}
        >
            <Image src="/images/no_data.svg" width="20%" my={4}></Image>
            <Text fontSize="xl" mb={8}>
                There is nothing here as of now!
            </Text>
        </Box>
    )
}

export default NoData;
