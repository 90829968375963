import { useState } from "react";
import axios from "axios";
import { FormControl, FormLabel, Select, Switch, Stack, Button, Input, Box, Heading } from "@chakra-ui/react";
import { CONFIG } from "../utils/constants";
import React from "react";

export default function SkillForm({ onSkillAdd }: any) {
    const [name, setName] = useState("");
    const [type, setType] = useState("");
    const [isActive, setIsActive] = useState(false);

    const handleFormSubmit = async (event: any) => {
        event.preventDefault();
        const skillObj = {
            name,
            type,
            is_active: isActive
        };
        try {
            await axios.post(`${CONFIG.API_URL}/skills/`, skillObj);
            setName('');
            setType('');
            setIsActive(false);
            onSkillAdd();
        } catch (error) {
            console.log("Save Error: ", error);
        }
    }

    return (
        <Box
            boxShadow={'2xl'}
            my={4}
            padding={4}
            borderWidth="1px"
            borderRadius="lg"
            bg="white">
            <Heading fontSize={"lg"} mb={4}>Add New Skill</Heading>
            <form onSubmit={handleFormSubmit}>
                <Stack>
                    <FormControl isRequired>
                        <FormLabel>Skill</FormLabel>
                        <Input type="text" name="name" value={name} placeholder="Skill name" onChange={(e) => setName(e.target.value)}></Input>
                    </FormControl>
                    <FormControl isRequired>
                        <FormLabel>Skill Type</FormLabel>
                        <Select placeholder='Select skill type' name="type" value={type} onChange={(e) => setType(e.target.value)}>
                            <option value="LANGUAGE">Language</option>
                            <option value="FRAMEWORK">Framework</option>
                            <option value="TOOLS">Tools</option>
                            <option value="SERVICE">Service</option>
                        </Select>
                    </FormControl>
                    <FormControl>
                        <FormLabel htmlFor="is_active">Is Active</FormLabel>
                        <Switch name="is_active" id="is_active" isChecked={isActive} onChange={(e) => setIsActive(e.target.checked)} />
                    </FormControl>
                    <Button mt={4} type="submit" colorScheme="blue">Submit</Button>
                </Stack>
            </form>
        </Box>
    );
}