import EmployeeCard from "./EmployeeCard";
import { useState, useEffect } from "react";
import axios from "axios";
import { SimpleGrid, Box, Heading, Button, Flex, Spacer, ButtonGroup, Link, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { CONFIG } from "../utils/constants";
import React from "react";
import { useSelector } from "react-redux";
import ServiceCard from "./ServiceCard";

export default function Services() {
    const [services, setServices] = useState([]);
    const [viewType, setViewType] = useState("table");
    const isAdmin = useSelector((state: any) => state.user.is_admin);
    const uid = useSelector((state: any) => state.user.uid);
    const navigate = useNavigate();
    const toast = useToast();
    const token = localStorage.getItem(CONFIG.TOKEN_KEY);
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const fetchData = async () => {
        const result = await axios.get(`${CONFIG.API_URL}/services`);
        setServices(result.data.data);
    };

    useEffect(() => {
        fetchData();
    }, []);
    return (
        <Box>
            <Flex my={4} minWidth='max-content' alignItems='center' gap='2'>
                <Box p='2'>
                    <Heading size='lg'>Services</Heading>
                </Box>
                <Spacer />
                {
                    isAdmin &&
                    <ButtonGroup gap='2'>
                        <Button colorScheme='teal'>Add New</Button>
                    </ButtonGroup>
                }
            </Flex>
            <SimpleGrid minChildWidth='230px' spacing={10} py={5}>
                {
                    services.map((service: any) => (
                        <Link
                            href={service.link}
                            target="_blank"
                        >
                            <ServiceCard key={service.uid} serviceInfo={service} />
                        </Link>
                    ))
                }
            </SimpleGrid>
        </Box >
    );
}