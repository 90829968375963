import { createSlice } from "@reduxjs/toolkit";
export const UserSlice = createSlice({
    name: 'User',
    initialState: {
        uid: null,
        is_admin: false,
        roles: [],
        profile: null
    },
    reducers: {
        setUid: (state, action) => {
            state.uid = action.payload;
        },
        setAdmin: (state, action) => {
            state.is_admin = action.payload;
        },
        setRoles: (state, action) => {
            state.roles = action.payload;
        },
        setProfile: (state, action) => {
            state.profile = action.payload;
        },
        resetUser: (state) => {
            state.is_admin = false;
            state.uid = null;
            state.roles = [];
            state.profile = null;
        }
    }
});

export const { setUid, setAdmin, setRoles, setProfile, resetUser } = UserSlice.actions;

export default UserSlice.reducer;