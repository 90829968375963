import React from 'react';
import { Box, Heading, Text, Button, Image } from '@chakra-ui/react';

const NotFoundPage = () => {
    return (
        <Box
            height="100vh"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
        >
            <Image src="/images/page_not_found.svg" maxWidth="60vw"></Image>
            <Heading fontSize="6xl" mb={4}>
                Oops, Page Not Found!
      </Heading>
            <Text fontSize="xl" mb={8}>
                The page you're looking for doesn't exist.
      </Text>
            <Button colorScheme="teal" size="lg" onClick={() => window.location.href = '/'}>
                Go to Home
      </Button>
        </Box>
    );
};

export default NotFoundPage;
