import { useState, useEffect } from "react";
import axios from "axios";
import { FormControl, FormLabel, Select, Stack, Button, Input, Box, Flex, Heading, Spacer, Divider, HStack, useToast } from "@chakra-ui/react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { CONFIG } from "../utils/constants";
import React from "react";

export default function AddEmployeeForm() {
    const [designations, setDesignations] = useState([]);
    const [statuses, setStatuses] = useState([]);

    const [employee, setEmployee] = useState({
        employee_id: '',
        tl_id: '',
        firstname: '',
        lastname: '',
        work_email: '',
        mobile: '',
        designation: '',
        joining_date: moment().format("YYYY-MM-DD"),
        leave_date: '',
        status: '',
        is_active: true
    });

    const toast = useToast();
    const navigate = useNavigate();

    const fetchData = async () => {
        const designations = await axios.get(`${CONFIG.API_URL}/designations`);
        const statuses = await axios.get(`${CONFIG.API_URL}/employee_statuses`);

        setDesignations(designations.data.data);
        setStatuses(statuses.data.data);
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleInputChange = (event: any) => {
        const target = event.target;
        setEmployee({
            ...employee,
            [target.name]: target.value
        });
    }

    const handleFormSubmit = async (event: any) => {
        event.preventDefault();
        try {
            await axios.post(`${CONFIG.API_URL}/employees/`, employee);
            navigate("/employees");
            toast({
                position: 'top-right',
                title: 'Employee added successfully',
                status: 'success',
                duration: 3000,
                isClosable: true
            });
        } catch (error) {
            toast({
                position: 'top-right',
                title: 'Unable to add employee: ' + error,
                status: 'error',
                duration: 3000,
                isClosable: true
            });
        }
    }

    return (
        <Box
            my={4}
            px={6}
            py={2}
            backgroundColor="white">
            <Flex my={2} minWidth='max-content' alignItems='center' gap='2'>
                <Box p='2'>
                    <Heading size='lg'>Add Employee</Heading>
                </Box>
                <Spacer />
            </Flex>
            <Divider mb={2} />
            <form onSubmit={handleFormSubmit}>
                <HStack spacing={4} mb={2}>
                    <FormControl isRequired>
                        <FormLabel>Employee ID</FormLabel>
                        <Input type="text" name="employee_id" placeholder="SST/IT/SD/XXX" onChange={handleInputChange}></Input>
                    </FormControl>
                    <FormControl isRequired>
                        <FormLabel>TL ID</FormLabel>
                        <Input type="text" name="tl_id" placeholder="TL ID" onChange={handleInputChange}></Input>
                    </FormControl>
                </HStack>
                <HStack spacing={4} mb={2}>
                    <FormControl isRequired>
                        <FormLabel>Firstname</FormLabel>
                        <Input type="text" name="firstname" placeholder="First name" onChange={handleInputChange}></Input>
                    </FormControl>
                    <FormControl isRequired>
                        <FormLabel>Lastname</FormLabel>
                        <Input type="text" name="lastname" placeholder="Last name" onChange={handleInputChange}></Input>
                    </FormControl>
                </HStack>
                <HStack spacing={4} mb={2}>
                    <FormControl isRequired>
                        <FormLabel>Work Email</FormLabel>
                        <Input type="text" name="work_email" placeholder="Work Email" onChange={handleInputChange}></Input>
                    </FormControl>
                    <FormControl isRequired>
                        <FormLabel>Mobile</FormLabel>
                        <Input type="text" name="mobile" placeholder="Mobile" onChange={handleInputChange}></Input>
                    </FormControl>
                </HStack>
                <HStack spacing={4} mb={2}>
                    <FormControl isRequired>
                        <FormLabel>Joining Date</FormLabel>
                        <Input type="date" name="joining_date" placeholder="Joining Date" onChange={handleInputChange}></Input>
                    </FormControl>
                    <FormControl>
                        <FormLabel>Leave Date</FormLabel>
                        <Input type="date" name="leave_date" placeholder="Leave Date" onChange={handleInputChange}></Input>
                    </FormControl>
                </HStack>
                <HStack spacing={4} mb={2}>
                    <FormControl isRequired>
                        <FormLabel>Designation</FormLabel>
                        <Select placeholder='Select Designation' name="designation" onChange={handleInputChange}>
                            {
                                designations.map((d: any) => (
                                    <option key={d._id} value={d._id}>{d.name}</option>
                                ))
                            }
                        </Select>
                    </FormControl>
                    <FormControl isRequired>
                        <FormLabel>Status</FormLabel>
                        <Select placeholder='Select Status' name="status" onChange={handleInputChange}>
                            {
                                statuses.map((s: any) => (
                                    <option key={s._id} value={s._id}>{s.name}</option>
                                ))
                            }
                        </Select>
                    </FormControl>
                </HStack>
                <HStack spacing={4} mb={2}>
                    <FormControl>
                        <FormLabel>Jira ID</FormLabel>
                        <Input type="text" name="Jira ID" placeholder="Jira ID" onChange={handleInputChange}></Input>
                    </FormControl>
                    <FormControl>
                        <FormLabel>TL Email</FormLabel>
                        <Input type="text" name="tl_email" placeholder="TL Email" onChange={handleInputChange}></Input>
                    </FormControl>
                </HStack>
                <Stack>
                    <Button my={4} type="submit" colorScheme="blue" size="md">Submit</Button>
                </Stack>
            </form>
        </Box>
    );
}