import { useState, useEffect } from "react";
import axios, { AxiosError } from "axios";
import { Box, Heading, Divider, useToast, HStack, FormControl, FormLabel, RadioGroup, Radio, Input, Spacer, VStack, Text } from "@chakra-ui/react";
import { CONFIG } from "../utils/constants";
import React from "react";
import moment from "moment";
import { Bar } from "react-chartjs-2";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export default function Analytics() {
    const [isLoading, setIsLoading] = useState(false);
    const [report, setReport] = useState<any>({});
    const [reportType, setReportType] = useState<any>("daily");
    const [reportDate, setReportDate] = useState(moment().format("YYYY-MM-DD"));
    const [preparedData, setPreparedData] = useState<any>({ labels: [], datasets: [] });
    const toast = useToast();
    const token = localStorage.getItem(CONFIG.TOKEN_KEY);
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const fetchData = async () => {
        try {
            let queryParams: any = {};
            if (moment(reportDate).isValid()) {
                queryParams.report_date = reportDate;
            }
            if (reportType) {
                queryParams.report_type = reportType;
            }
            const result = await axios.get(`${CONFIG.API_URL}/reports/usage`, { params: queryParams });
            if (result.data.data) {
                setReport(result.data.data);
                prepareChartData(result.data.data.report);
            }
        } catch (error) {
            if (error instanceof AxiosError) {
                toast({
                    position: 'top-right',
                    title: error.response ? error.response.data.message : error.message,
                    status: 'error',
                    duration: 3000,
                    isClosable: true
                });
            } else {
                toast({
                    position: 'top-right',
                    title: 'Unable to fetch data',
                    status: 'error',
                    duration: 3000,
                    isClosable: true
                });
            }
        }
    };

    function onDateChange(date: any) {
        setReportDate(date);
    }

    function sendClarificationMail() {
        // console.log("Send Clarification Mail");
    }

    useEffect(() => {
        fetchData();
    }, [reportDate, reportType]);

    function prepareChartData(rawData: any[]) {
        let labels: string[] = [];
        let datasets: any[] = [
            {
                label: 'Daily',
                data: [],
                backgroundColor: 'rgba(255, 99, 132, 0.5)'
            },
            {
                label: 'Weekly',
                data: [],
                backgroundColor: 'rgba(53, 162, 235, 0.5)'
            },
            {
                label: 'Monthly',
                data: [],
                backgroundColor: 'rgba(255, 159, 64, 0.5)'
            }
        ];
        const sortedData = sortRawData(rawData);
        rawData.forEach((rd: any) => {
            labels.push(`${rd.user[0].firstname} ${rd.user[0].lastname}`);
            const tld = rd.types.find((tp: any) => tp.type == "TL_DAILY");
            const tlw = rd.types.find((tp: any) => tp.type == "TL_WEEKLY");
            const tlm = rd.types.find((tp: any) => tp.type == "TL_MONTHLY");
            datasets[0].data.push(tld ? tld.count : 0);
            datasets[1].data.push(tlw ? tlw.count : 0);
            datasets[2].data.push(tlm ? tlm.count : 0);
        });

        setPreparedData({ labels, datasets });
    }

    /**
     * Sorts raw data based on total number of calls made for any type of report (daily + weekly + monthly)
     * @param rawData Raw data received from API server
     */
    function sortRawData(rawData: any[]) {
        rawData.forEach((rd) => {
            rd.total_calls = 0;
            const tld = rd.types.find((tp: any) => tp.type == "TL_DAILY");
            const tlw = rd.types.find((tp: any) => tp.type == "TL_WEEKLY");
            const tlm = rd.types.find((tp: any) => tp.type == "TL_MONTHLY");
            rd.total_calls += tld ? tld.count : 0;
            rd.total_calls += tlw ? tlw.count : 0;
            rd.total_calls += tlm ? tlm.count : 0;
        });
        const sortedData = rawData.sort((a, b) => b.total_calls - a.total_calls);
        console.log("Sorted Data: ", sortedData);
        return sortedData;
    }

    /**
     * Chart Data
     */

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
                title: {
                    display: true,
                    text: 'Server Hours Call Types'
                }
            }
        },
    };

    return (
        <Box
            boxShadow={'2xl'}
            my={4}
            padding={4}
            borderWidth="1px"
            borderRadius="lg"
            bg="white">
            <HStack>
                <VStack>
                    <Heading size='md'>Employee Wise Usage</Heading>
                    <Text fontSize="sm">Report Period: {report?.report_period}</Text>
                </VStack>
                <Spacer />
                <form>
                    <HStack spacing={5}>
                        <FormControl py={3}>
                            <FormLabel>Report Period:</FormLabel>
                            <RadioGroup onChange={setReportType} value={reportType}>
                                <HStack spacing={3}>
                                    <Radio value="daily">Daily</Radio>
                                    <Radio value="weekly">Weekly</Radio>
                                    <Radio value="monthly">Monthly</Radio>
                                </HStack>
                            </RadioGroup>
                        </FormControl>
                        <FormControl py={3}>
                            <FormLabel htmlFor="report_date">Report Date:</FormLabel>
                            <Input type="date" value={reportDate} onChange={(e: any) => onDateChange(e.target.value)}></Input>
                        </FormControl>
                    </HStack>
                </form>
            </HStack>
            <Divider my={2} />
            <Bar options={options} data={preparedData}></Bar>
        </Box>
    );
}

export interface ChartData {
    name: string,
    TL_DAILY: number,
    TL_WEEKLY: number,
    TL_MONTHLY: number
}