import {
    Heading,
    Image,
    Stack,
    Text,
    useColorModeValue,
    Grid,
    GridItem,
    HStack,
    Box,
    Tabs,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    TableContainer,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    Divider,
    IconButton,
    useToast,
    ButtonGroup
} from '@chakra-ui/react';
import { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { EmailIcon, PhoneIcon, ChatIcon, CalendarIcon, EditIcon, DeleteIcon } from '@chakra-ui/icons';
import moment from 'moment';
import EmployeeSkillForm from '../components/EmployeeSkillForm';
import { CONFIG } from '../utils/constants';
import React from 'react';
import { useSelector } from 'react-redux';

export default function EmployeeDetails() {
    let { uid } = useParams();
    const [employee, setEmployee] = useState<any>({});
    const [skills, setSkills] = useState([]);
    const isAdmin = useSelector((state: any) => state.user.is_admin);
    const loggedUserUid = useSelector((state: any) => state.user.uid);

    const toast = useToast();
    const token = localStorage.getItem(CONFIG.TOKEN_KEY);
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const fetchEmployeDetails = async () => {
        try {
            const result = await axios.get(`${CONFIG.API_URL}/employees/details/${uid}`);
            setEmployee(result.data.data);
        } catch (error) {
            toast({
                position: 'top-right',
                title: 'Unable to fetch employee details',
                status: 'error',
                duration: 3000,
                isClosable: true
            });
        }
    };

    const fetchSkills = async () => {
        try {
            const result = await axios.get(`${CONFIG.API_URL}/skills`);
            setSkills(result.data.data);
        } catch (error) {
            toast({
                position: 'top-right',
                title: 'Unable to fetch skills',
                status: 'error',
                duration: 3000,
                isClosable: true
            });
        }
    };

    useEffect(() => {
        fetchEmployeDetails();
        fetchSkills();
    }, []);

    function handleSkillAdd() {
        toast({
            position: 'top-right',
            title: 'Skill added successfully',
            status: 'success',
            duration: 3000,
            isClosable: true
        });
        fetchEmployeDetails();
    }

    async function deleteEmployeeSkill(uid: string) {
        try {
            await axios.delete(`${CONFIG.API_URL}/employees/${employee.uid}/skills/delete/${uid}`);
            toast({
                position: 'top-right',
                title: 'Skill removed successfully',
                status: 'success',
                duration: 3000,
                isClosable: true
            });
            fetchEmployeDetails();
        } catch (error) {
            toast({
                position: 'top-right',
                title: 'Unable to delete skill',
                status: 'error',
                duration: 3000,
                isClosable: true
            });
        }
    }

    return (
        <Grid py={5} gap={5} templateColumns={'350px 1fr'}>
            <GridItem>
                <Box
                    boxShadow={'2xl'}
                    borderWidth="1px"
                    borderRadius="lg"
                    bg={useColorModeValue('white', 'gray.900')}>
                    <Stack
                        flex={1}
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        borderWidth="1px"
                        borderRadius="lg"
                        w={'100%'}
                        direction="row">
                        <Image
                            objectFit="cover"
                            boxSize="100%"
                            padding={5}
                            src={
                                'https://ui-avatars.com/api/?name=' + employee.firstname + '+' + employee.lastname
                            }
                        />
                        <Heading fontSize={'2xl'} fontFamily={'body'}>
                            {employee.firstname} {employee.lastname}
                        </Heading>
                        <Text fontWeight={600} color={'gray.500'} size="sm" mb={4}>
                            {employee.employee_profile?.designation.name}
                        </Text>
                    </Stack>
                    <Box padding={4}>
                        <HStack spacing="20px" py={1}>
                            <ChatIcon />
                            <Text>{employee.employee_profile?.employee_id}</Text>
                        </HStack>
                        <HStack spacing="20px" py={1}>
                            <EmailIcon />
                            <Text>{employee.employee_profile?.work_email}</Text>
                        </HStack>
                        <HStack spacing="20px" py={1}>
                            <PhoneIcon />
                            <Text>{employee.employee_profile?.mobile}</Text>
                        </HStack>
                        <HStack spacing="20px" py={1}>
                            <CalendarIcon />
                            <Text>{moment(employee.employee_profile?.joining_date * 1000).format("DD-MMM-YYYY")}</Text>
                        </HStack>
                    </Box>
                </Box>
            </GridItem>
            <GridItem>
                <Box
                    boxShadow={'2xl'}
                    padding={4}
                    borderWidth="1px"
                    borderRadius="lg"
                    bg={useColorModeValue('white', 'gray.900')}>
                    <Tabs>
                        <TabList>
                            <Tab>Skills</Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel>
                                {
                                    (isAdmin || loggedUserUid == uid) &&
                                    <Box padding={4} borderWidth="1px" borderRadius="lg">
                                        <Heading fontSize={'2xl'} fontFamily={'body'}>Add New Skill</Heading>
                                        <Divider my={3} />
                                        <EmployeeSkillForm employeeUid={employee.uid} skillsList={skills} onSkillAdd={handleSkillAdd} />
                                    </Box>
                                }
                                <TableContainer>
                                    <Table variant="simple">
                                        <Thead>
                                            <Tr>
                                                <Th>#</Th>
                                                <Th>Skill</Th>
                                                <Th>Type</Th>
                                                <Th>Level</Th>
                                                <Th>Last Updated</Th>
                                                {
                                                    (isAdmin || loggedUserUid == uid) &&
                                                    <Th>Action</Th>
                                                }
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {
                                                employee.skills?.map((sk: any, ind: number) => (
                                                    <Tr key={sk.uid}>
                                                        <Td>{ind + 1}</Td>
                                                        <Td>{sk.skill[0].name}</Td>
                                                        <Td>{sk.skill[0].type}</Td>
                                                        <Td>{sk.value}/10</Td>
                                                        <Td>{moment(sk.date * 1000).fromNow()}</Td>
                                                        <Td>
                                                            {
                                                                (isAdmin || loggedUserUid == uid) &&
                                                                <ButtonGroup isAttached={true}>
                                                                    {/* <IconButton size='xs' aria-label='Edit Skill' colorScheme='teal' icon={<EditIcon />} /> */}
                                                                    <IconButton size='xs' aria-label='Remove Skill' colorScheme='red' onClick={() => deleteEmployeeSkill(sk.uid)} icon={<DeleteIcon />} />
                                                                </ButtonGroup>
                                                            }
                                                        </Td>
                                                    </Tr>
                                                ))
                                            }
                                        </Tbody>
                                    </Table>
                                </TableContainer>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </Box>
            </GridItem>
        </Grid>
    );
}