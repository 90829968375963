import { useState, useEffect } from "react";
import axios from "axios";
import {
    Stat,
    StatLabel,
    StatNumber,
    Box,
    Heading,
    Divider,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    VStack,
    Text,
    Stack,
    HStack,
    Tooltip,
    Button,
    Flex,
    SimpleGrid,
    TableContainer,
    Table,
    Thead,
    Th,
    Tbody,
    Tr,
    Td,
    Image,
    Skeleton
} from "@chakra-ui/react";
import { CONFIG } from "../utils/constants";
import React from "react";
import moment from "moment";
import { FaThumbsUp, FaThumbsDown } from "react-icons/fa";
import { useSelector } from "react-redux";
import NoData from "../components/NoData";

export default function Dashboard() {
    const [stats, setDashboardStats] = useState({ employees: 0, skills: 0, designations: 0 });
    const [notifications, setNotifications] = useState<any[]>([]);
    const [quote, setQuote] = useState<any>({});
    const [holidays, setHolidays] = useState<any[]>([]);
    const [isLiked, setIsLiked] = useState<boolean>(false);
    const [isDisLiked, setIsDisLiked] = useState<boolean>(false);
    const uid = useSelector((state: any) => state.user.uid);
    const token = localStorage.getItem(CONFIG.TOKEN_KEY);
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const fetchData = async () => {
        try {
            const result = await axios.get(`${CONFIG.API_URL}/dashboard/stats`);
            const notifResult = await axios.get(`${CONFIG.API_URL}/notifications`);
            const quote = await axios.get(`${CONFIG.API_URL}/dashboard/quote_of_the_day`);
            const holidays = await axios.get(`${CONFIG.API_URL}/dashboard/holidays`);
            setDashboardStats(result.data.data);
            setNotifications(notifResult.data.data);
            setQuote(quote.data.data);
            setHolidays(holidays.data.data);
        } catch (error) {
            console.log("Error getting dashboard stats : ", error);
        }
    };

    async function quoteReaction(like: boolean) {
        let postObj = { like };
        try {
            const result = await axios.post(`${CONFIG.API_URL}/dashboard/quote_of_the_day/react/${quote.uid}`, postObj);
            setQuote(result.data.data);
        } catch (error) {
            console.log("Error reacting to quote : ", error);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    // handle quote reaction change
    useEffect(() => {
        if (quote) {
            if (quote.likes?.includes(uid)) {
                setIsLiked(true);
            } else {
                setIsLiked(false);
            }

            if (quote.dislikes?.includes(uid)) {
                setIsDisLiked(true);
            } else {
                setIsDisLiked(false);
            }
        }
    }, [quote]);
    return (
        <>
            <SimpleGrid columns={{ base: 1, sm: 1, md: 3 }} my={4} boxShadow="md" textAlign={{ base: 'center', sm: 'center', md: 'start' }}>
                <Stat
                    padding={2}
                    bg='white'>
                    <StatLabel>Active Employees</StatLabel>
                    <StatNumber>{stats.employees}</StatNumber>
                </Stat>
                <Stat
                    padding={2}
                    bg='white'>
                    <StatLabel>Skills</StatLabel>
                    <StatNumber>{stats.skills}</StatNumber>
                </Stat>
                <Stat
                    padding={2}
                    bg='white'>
                    <StatLabel>Designations</StatLabel>
                    <StatNumber>{stats.designations}</StatNumber>
                </Stat>
            </SimpleGrid>
            <Skeleton isLoaded={quote.uid != undefined}>
                <Stack
                    bg='white'
                    my={4}
                    py={8}
                    px={8}
                    spacing={{ base: 8, md: 10 }}
                    align={'center'}
                    direction={'column'}
                    boxShadow="md"
                >

                    <Text fontSize={{ base: 'xl', md: '2xl' }} textAlign={'center'} maxW={'3xl'}>{quote.content}</Text>
                    <Box textAlign={'center'}>
                        <Text fontWeight={600}>- {quote.author}</Text>
                    </Box>
                    <Box textAlign={'center'}>
                        <HStack gap={2}>
                            <Tooltip label="Like">
                                <Button colorScheme={isLiked ? "teal" : "gray"} onClick={() => quoteReaction(true)}>
                                    <FaThumbsUp />&nbsp;{quote.likes?.length ? `(${quote.likes?.length})` : ""}
                                </Button>
                            </Tooltip>
                            <Tooltip label="Dislike">
                                <Button colorScheme={isDisLiked ? "teal" : "gray"} onClick={() => quoteReaction(false)}>
                                    <FaThumbsDown />&nbsp;{quote.dislikes?.length ? `(${quote.dislikes?.length})` : ""}
                                </Button>
                            </Tooltip>
                        </HStack>
                    </Box>
                </Stack>
            </Skeleton>
            <SimpleGrid columns={{ base: 1, sm: 1, md: 2 }} spacing={5}>
                <Box
                    my={1}
                    padding={4}
                    bg="white"
                    boxShadow="md">
                    <Heading size="sm" mb={2}>News & Alerts</Heading>
                    <Divider mb={2} />
                    {
                        (notifications.length > 0) ? <VStack spacing={3}>
                            {
                                notifications.map((notif: any, ind: number) => (
                                    <Alert key={ind} status={notif.type} variant="left-accent">
                                        <AlertIcon />
                                        <Box>
                                            <AlertTitle>{notif.title}</AlertTitle>
                                            <AlertDescription>{notif.description}</AlertDescription>
                                            <Text color="gray">{moment(notif.datetime).fromNow()}</Text>
                                        </Box>
                                    </Alert>
                                ))
                            }
                        </VStack>
                            :
                            <NoData />
                    }
                </Box>
                <Box
                    my={1}
                    padding={4}
                    bg="white"
                    boxShadow="md">
                    <Heading size="sm" mb={2}>Holiday Policy (Year: 2024)</Heading>
                    <Divider mb={2} />
                    {
                        (holidays.length > 0) ?

                            <TableContainer>
                                <Table variant="striped" colorScheme="gray">
                                    <Thead>
                                        <Tr>
                                            <Th>#</Th>
                                            <Th>Date</Th>
                                            <Th>Day</Th>
                                            <Th>Holiday</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {
                                            holidays.map((hd: any) => (
                                                <Tr key={hd.id}>
                                                    <Td>{hd.id}</Td>
                                                    <Td>{hd.date}</Td>
                                                    <Td>{hd.day}</Td>
                                                    <Td>{hd.holiday}</Td>
                                                </Tr>
                                            ))
                                        }
                                    </Tbody>
                                </Table>
                            </TableContainer>
                            :
                            <NoData />
                    }
                </Box>
            </SimpleGrid>
        </>
    );
}