import React, { useState } from "react";
import { TableContainer, Table, Thead, Tr, Th, Tbody, Td, ButtonGroup, IconButton, Box, useToast, useDisclosure, Drawer, DrawerOverlay, DrawerContent, DrawerHeader, DrawerBody, LinkBox, Heading, LinkOverlay, Divider, Link } from "@chakra-ui/react";
import { CheckIcon, CloseIcon, EditIcon, DeleteIcon } from "@chakra-ui/icons";
import { useSelector } from "react-redux";
import { CONFIG } from "../utils/constants";
import axios from "axios";

interface DesignationsProps {
    designations: any[],
    onItemDelete: () => void
}

export default function DesignationsList(props: DesignationsProps) {
    const toast = useToast();
    const [employees, setEmployees] = useState<any[]>([]);
    const { isOpen, onOpen, onClose } = useDisclosure();

    const isAdmin = useSelector((state: any) => state.user.is_admin);
    const token = localStorage.getItem(CONFIG.TOKEN_KEY);
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    async function deleteItem(itemUid: string) {
        try {
            await axios.delete(`${CONFIG.API_URL}/designations/delete/${itemUid}`);
            toast({
                position: 'top-right',
                title: 'Designation removed successfully',
                status: 'success',
                duration: 3000,
                isClosable: true
            });
            props.onItemDelete();
        } catch (error) {
            toast({
                position: 'top-right',
                title: 'Unable to delete designation',
                status: 'error',
                duration: 3000,
                isClosable: true
            });
        }
    }

    function viewEmployeesList(emps: any[]) {
        if (emps.length) {
            setEmployees(emps);
        } else {
            setEmployees([]);
        }
        onOpen();
    }


    return (
        <>
            <Box
                boxShadow={'2xl'}
                my={4}
                padding={4}
                borderWidth="1px"
                borderRadius="lg"
                bg="white">
                <TableContainer>
                    <Table variant="striped">
                        <Thead>
                            <Tr>
                                <Th>Designation</Th>
                                {isAdmin && <Th>Active</Th>}
                                <Th>Employees</Th>
                                {isAdmin && <Th>Action</Th>}
                            </Tr>
                        </Thead>
                        <Tbody>
                            {
                                props.designations.map((d: any) => (
                                    <Tr key={d._id}>
                                        <Td>{d.name}</Td>
                                        {isAdmin && <Td>{d.is_active ? <CheckIcon /> : <CloseIcon />}</Td>}
                                        <Td>
                                            <Link href="#" onClick={() => { viewEmployeesList(d.employees) }}>
                                                {d.employees.length}
                                            </Link>
                                        </Td>
                                        <Td>
                                            {
                                                isAdmin &&
                                                <ButtonGroup isAttached>
                                                    <IconButton size='xs' aria-label='Edit Designation' colorScheme='teal' icon={<EditIcon />} />
                                                    <IconButton size='xs' aria-label='Remove Designation' colorScheme='red' onClick={() => deleteItem(d.uid)} icon={<DeleteIcon />} />
                                                </ButtonGroup>
                                            }
                                        </Td>
                                    </Tr>
                                ))
                            }
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
            <Drawer size="md" placement="right" onClose={onClose} isOpen={isOpen}>
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerHeader borderBottomWidth='1px'>Employees</DrawerHeader>
                    <DrawerBody>
                        {
                            employees.map((emp: any) => (
                                <>
                                    <LinkBox>
                                        <Heading size="md" my={2}>
                                            <LinkOverlay href={`/employees/${emp.uid}`}>
                                                {emp.firstname} {emp.lastname}
                                            </LinkOverlay>
                                        </Heading>
                                    </LinkBox>
                                    <Divider mb={2} />
                                </>
                            ))
                        }
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    );
}