import { useState, useEffect } from "react";
import axios from "axios";
import {
    GridItem,
    Grid,
    useToast
} from "@chakra-ui/react";
import EmployeeStatusForm from "../components/EmployeeStatusForm";
import { CONFIG } from "../utils/constants";
import React from "react";
import EmployeeStatusList from "../components/EmployeeStatusList";
import { useSelector } from "react-redux";

export default function EmployeeStatuses() {
    const [statuses, setStatuses] = useState([]);
    const toast = useToast();
    const isAdmin = useSelector((state: any) => state.user.is_admin);
    const token = localStorage.getItem(CONFIG.TOKEN_KEY);
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const fetchData = async () => {
        const result = await axios.get(`${CONFIG.API_URL}/employee_statuses`);
        setStatuses(result.data.data);
    };

    useEffect(() => {
        fetchData();
    }, []);

    function handleStatusAdd() {
        toast({
            position: 'top-right',
            title: 'Employee status added successfully',
            status: 'success',
            duration: 3000,
            isClosable: true
        });
        fetchData();
    }

    function handleStatusDelete() {
        fetchData()
    }

    return (
        isAdmin ?
            <Grid py={5} gap={5} templateColumns={'350px 1fr'}>
                <GridItem>
                    <EmployeeStatusForm onStatusAdd={handleStatusAdd} />
                </GridItem>
                <GridItem>
                    <EmployeeStatusList statuses={statuses} onItemDelete={handleStatusDelete}></EmployeeStatusList>
                </GridItem>
            </Grid>
            :
            <EmployeeStatusList statuses={statuses} onItemDelete={handleStatusDelete}></EmployeeStatusList>
    );
}