import { Box, Flex, Avatar, Heading, Text, useToast, Badge, Divider, HStack, Stack } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { CONFIG } from "../utils/constants";
import { ChatIcon, EmailIcon, PhoneIcon, CalendarIcon } from "@chakra-ui/icons";
import moment from "moment";

const UserProfile = () => {
    const [profile, setProfile] = useState<any>({});
    const toast = useToast();
    const token = localStorage.getItem(CONFIG.TOKEN_KEY);
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;


    const fetchUserProfile = async () => {
        try {
            const result = await axios.get(`${CONFIG.API_URL}/user/profile`);
            setProfile(result.data.data);
        } catch (error) {
            toast({
                position: 'top-right',
                title: 'Unable to fetch employee details',
                status: 'error',
                duration: 3000,
                isClosable: true
            });
        }
    };

    useEffect(() => {
        fetchUserProfile();
    }, []);

    return (
        <Box my={4} p={4} boxShadow="md" borderRadius="md" bg="white" maxW={500} marginLeft='auto' marginRight='auto'>
            <Flex align="center">
                <Avatar src={'#'} name={profile.fullname} size="lg" />
                <Box ml={4}>
                    <Heading size="lg">
                        {profile.fullname}
                        <Badge m={2} variant='outline' colorScheme={profile.is_active ? 'green' : 'red'}>{profile.is_active ? 'Active' : 'Inactive'}</Badge>
                    </Heading>
                    <Text color="gray.500">{profile.email}</Text>
                </Box>
            </Flex>
            <Divider my={5} />
            <Stack>
                <HStack spacing="20px" py={1}>
                    <ChatIcon />
                    <Text>{profile.employee_profile?.employee_id}</Text>
                </HStack>
                <HStack spacing="20px" py={1}>
                    <EmailIcon />
                    <Text>{profile.employee_profile?.work_email}</Text>
                </HStack>
                <HStack spacing="20px" py={1}>
                    <PhoneIcon />
                    <Text>{profile.employee_profile?.mobile}</Text>
                </HStack>
                <HStack spacing="20px" py={1}>
                    <CalendarIcon />
                    <Text>{moment(profile.employee_profile?.joining_date * 1000).format("DD-MMM-YYYY")}</Text>
                </HStack>
            </Stack>
        </Box>
    );
};

export default UserProfile;
