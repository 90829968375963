import {
    Heading,
    Avatar,
    Box,
    Text,
    Stack,
    Badge,
    useColorModeValue,
    HStack,
    useToast,
    IconButton,
    Spacer,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ViewIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { CONFIG } from '../utils/constants';
import React from 'react';
import { useSelector } from 'react-redux';


export default function EmployeeCard({ empInfo, onEmployeeDelete }: any) {
    const navigate = useNavigate();
    const toast = useToast();
    const isAdmin = useSelector((state: any) => state.user.is_admin);
    const uid = useSelector((state: any) => state.user.uid);

    function viewEmployeeDetails(uid: string) {
        navigate(`/employees/${uid}`);
    }

    function editEmployeeDetails(uid: string) {
        navigate(`/employees/${uid}/edit`);
    }

    async function deleteEmployee(uid: string) {
        if (!isAdmin) {
            toast({
                position: 'top-right',
                title: 'Permission Denied!',
                status: 'error',
                duration: 3000,
                isClosable: true
            });
            return;
        }
        try {
            await axios.delete(`${CONFIG.API_URL}/employees/delete/${uid}`);
            toast({
                position: 'top-right',
                title: 'Employee removed successfully',
                status: 'success',
                duration: 3000,
                isClosable: true
            });
            onEmployeeDelete();
        } catch (error) {
            toast({
                position: 'top-right',
                title: 'Unable to remove employee: ' + error,
                status: 'error',
                duration: 3000,
                isClosable: true
            });
        }
    }

    return (
        <Box
            maxW={'320px'}
            w={'full'}
            bg={useColorModeValue('white', 'gray.900')}
            boxShadow={'2xl'}
            rounded={'lg'}
            textAlign={'center'}>
            <Box p={6}>
                <Avatar
                    size={'xl'}
                    name={empInfo.fullname}
                    mb={4}
                    pos={'relative'}
                    _after={{
                        content: '""',
                        w: 4,
                        h: 4,
                        bg: (empInfo.is_active) ? 'green.300' : 'red.300',
                        border: '2px solid white',
                        rounded: 'full',
                        pos: 'absolute',
                        bottom: 0,
                        right: 3,
                    }}
                />
                <Heading fontSize={'2xl'} fontFamily={'body'}>
                    {empInfo.firstname} {empInfo.lastname}
                </Heading>
                <Text fontWeight={600} color={'gray.500'} mb={4}>
                    {empInfo.employee_profile.designation.name}
                </Text>

                <Stack align={'center'} justify={'center'} direction={'row'} mt={6}>
                    {
                        empInfo.is_active ?
                            <Badge
                                px={2}
                                py={1}
                                bg='gray.50'
                                fontWeight='400'
                                variant="outline"
                                colorScheme="teal"
                                borderRadius="md">
                                {empInfo.employee_profile.employee_id}
                            </Badge>
                            :
                            <Badge
                                px={2}
                                py={1}
                                fontWeight='400'
                                variant="outline"
                                colorScheme="red"
                                borderRadius="md">
                                Inactive
                        </Badge>
                    }
                </Stack>
            </Box>
            <HStack m={2} spacing={2}>
                <IconButton aria-label="View Employee" colorScheme="gray" icon={<ViewIcon />} onClick={() => viewEmployeeDetails(empInfo.uid)} />
                {
                    (isAdmin || uid == empInfo.uid) &&
                    <>
                        <Spacer />
                        <IconButton aria-label="Edit Employee" colorScheme="gray" icon={<EditIcon />} onClick={() => editEmployeeDetails(empInfo.uid)} />
                    </>
                }
                {
                    isAdmin &&
                    <>
                        <Spacer />
                        <IconButton aria-label="Delete Employee" colorScheme="red" icon={<DeleteIcon />} onClick={() => deleteEmployee(empInfo.uid)} />
                    </>
                }
            </HStack>
        </Box>
    );
}