import { useState, useEffect } from "react";
import axios, { AxiosError } from "axios";
import moment from "moment";
import {
    TableContainer,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    Box,
    useColorModeValue,
    GridItem,
    Grid,
    useToast,
    Heading,
    Tag,
    Divider,
    Text,
    FormControl,
    FormLabel,
    Input,
    RadioGroup,
    HStack,
    Radio,
    Progress,
    Button,
    Alert,
    AlertIcon,
    AlertTitle,
} from "@chakra-ui/react";
import { ArrowDownIcon } from "@chakra-ui/icons";
import { CONFIG } from "../utils/constants";
import React from "react";

export default function ServerHoursReport() {
    const [isLoading, setIsLoading] = useState(false);
    const [report, setReport] = useState<any>({});
    const [reportType, setReportType] = useState<any>("daily");
    const [reportDate, setReportDate] = useState(moment().format("YYYY-MM-DD"));
    const toast = useToast();
    const token = localStorage.getItem(CONFIG.TOKEN_KEY);
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const fetchData = async () => {
        try {
            setIsLoading(true);
            let url = `${CONFIG.API_URL}/server_hours`;
            let queryParams: any = {};
            if (moment(reportDate).isValid()) {
                queryParams.report_date = reportDate;
            }
            if (reportType) {
                queryParams.report_type = reportType;
            }
            const result = await axios.get(url, { params: queryParams });
            setReport(result.data.data);

            setIsLoading(false);
            toast({
                position: 'top-right',
                title: 'Report updated',
                status: 'success',
                duration: 3000,
                isClosable: true
            });
        } catch (error) {
            let errMsg: string = 'Unable to fetch report';

            if (error instanceof AxiosError) {
                errMsg = error.response?.data?.message;
            }

            setIsLoading(false);
            toast({
                position: 'top-right',
                title: errMsg,
                status: 'error',
                duration: 3000,
                isClosable: true
            });
        }
    };

    function onDateChange(date: any) {
        setReportDate(date);
    }

    useEffect(() => {
        fetchData();
    }, [reportDate, reportType]);

    return (
        <Grid py={5} gap={5} templateColumns={'350px 1fr'}>
            <GridItem>
                <Box
                    boxShadow={'2xl'}
                    my={4}
                    padding={4}
                    borderWidth="1px"
                    borderRadius="lg"
                    bg={useColorModeValue('white', 'gray.900')}>
                    <Heading fontSize={"xl"} mb={2}>Server Hours Report</Heading>
                    <Heading color="gray" fontSize={"sm"} mb={2}>Period: {report.report_period}</Heading>
                    <Divider mb={2} />
                    <form>
                        <FormControl py={3}>
                            <FormLabel >Report Type:</FormLabel>
                            <RadioGroup onChange={setReportType} value={reportType}>
                                <HStack spacing={3}>
                                    <Radio value="daily">Daily</Radio>
                                    <Radio value="weekly">Weekly</Radio>
                                    <Radio value="monthly">Monthly</Radio>
                                </HStack>
                            </RadioGroup>
                        </FormControl>
                        <FormControl py={3}>
                            <FormLabel htmlFor="report_date">Report Date:</FormLabel>
                            <Input type="date" value={reportDate} onChange={(e) => onDateChange(e.target.value)}></Input>
                        </FormControl>
                    </form>
                </Box>

                <Box
                    boxShadow={'2xl'}
                    my={4}
                    padding={4}
                    borderWidth="1px"
                    borderRadius="lg"
                    bg={useColorModeValue('white', 'gray.900')}>
                    <Heading fontSize={"xl"} mb={2}>Report Details</Heading>
                    <Divider mb={2} />
                    <Heading fontSize={"md"} mb={2}>Period: {report.report_period}</Heading>
                    <Heading fontSize={"md"} mb={2}>Working Days: {report.working_days}</Heading>
                    <Heading fontSize={"md"} mb={2}>Working Hours: {report.working_hours}</Heading>
                </Box>
            </GridItem>
            <GridItem>
                <Box
                    boxShadow={'2xl'}
                    my={4}
                    padding={4}
                    borderWidth="1px"
                    borderRadius="lg"
                    bg={useColorModeValue('white', 'gray.900')}>
                    <TableContainer>
                        {isLoading ? <Progress size='xs' isIndeterminate variant='' /> : <></>}
                        <Alert status="info">
                            <AlertIcon />
                            <AlertTitle>Report will be shown untill previous day midnight only</AlertTitle>
                        </Alert>
                        <Table variant="simple">
                            <Thead>
                                <Tr>
                                    <Th>#</Th>
                                    <Th>Employee</Th>
                                    <Th>Time</Th>
                                    <Th>Difference</Th>
                                    <Th>Activity <ArrowDownIcon /></Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {
                                    report.report?.map((rp: any, ind: number) => (
                                        <Tr key={rp.id}>
                                            <Td>{ind + 1}</Td>
                                            <Td>
                                                <Text>{rp.title}</Text>
                                                <Text fontSize="sm" color="gray">Last Seen: {moment(rp.lastActivityTime).format("hh:mm A")}</Text>
                                            </Td>
                                            <Td>{rp.time}</Td>
                                            <Td>
                                                <Text color={rp.time_diff_is_negative ? "red" : "green"}>{rp.time_diff}</Text>
                                            </Td>
                                            <Td>{rp.activity}%</Td>
                                        </Tr>
                                    ))
                                }
                            </Tbody>
                        </Table>
                    </TableContainer>
                </Box>
            </GridItem>
        </Grid>
    );
}