import Header from "./header";
import Footer from "./footer";
import { Outlet } from "react-router-dom";
import { Container } from "@chakra-ui/react";
import React from "react";
export default function Layout() {
    return (
        <>
            <Header />
            <Container maxW='container.xl' minH='85vh'>
                <Outlet />
            </Container>
            <Footer />
        </>
    );
}