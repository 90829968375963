import { useState } from "react";
import axios from "axios";
import moment from "moment";
import { FormControl, FormLabel, Button, Input, HStack, Select, Slider, SliderTrack, SliderFilledTrack, SliderThumb, SliderMark, Tooltip } from "@chakra-ui/react";
import { CONFIG } from "../utils/constants";
import React from "react";

export default function EmployeeSkillForm({ employeeUid, skillsList, onSkillAdd }: any) {
    const [skillUid, setSkillUid] = useState("");
    const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
    const [value, setValue] = useState(5);
    const [showTooltip, setShowTooltip] = useState(false)

    const handleFormSubmit = async (event: any) => {
        event.preventDefault();
        const bodyObj = {
            skill_uid: skillUid,
            date,
            value
        };
        try {
            await axios.post(`${CONFIG.API_URL}/employees/${employeeUid}/skills`, bodyObj);
            // reset post obj
            setSkillUid("");
            setDate(moment().format("YYYY-MM-DD"));
            setValue(5);
            onSkillAdd();
        } catch (error) {
            console.log("Save Error: ", error);
        }
    }

    return (
        <form onSubmit={handleFormSubmit}>
            <HStack spacing={10}>
                <FormControl isRequired>
                    <FormLabel htmlFor="skill_uid">Skill</FormLabel>
                    <Select placeholder='Select skill' name="skill_uid" id="skill_uid" value={skillUid} onChange={(e) => setSkillUid(e.target.value)}>
                        {
                            skillsList?.map((sk: any) => (
                                <option key={sk.uid} value={sk.uid}>{sk.name}</option>
                            ))
                        }
                    </Select>
                </FormControl>
                <FormControl>
                    <FormLabel htmlFor="date">Date</FormLabel>
                    <Input type="date" name="date" id="date" value={date} onChange={(e) => setDate(e.target.value)} />
                </FormControl>
                <FormControl>
                    <FormLabel htmlFor="value">Value</FormLabel>
                    <Slider aria-label="Skill Level" defaultValue={5} value={value} max={10}
                        onChange={(val) => setValue(val)}
                        onMouseEnter={() => setShowTooltip(true)}
                        onMouseLeave={() => setShowTooltip(false)}>
                        <SliderMark value={0}>
                            0
                        </SliderMark>
                        <SliderMark value={10}>
                            10
                        </SliderMark>
                        <SliderTrack>
                            <SliderFilledTrack />
                        </SliderTrack>
                        <Tooltip
                            hasArrow
                            bg='teal.500'
                            color='white'
                            placement='top'
                            isOpen={showTooltip}
                            label={value}>
                            <SliderThumb />
                        </Tooltip>
                    </Slider>
                </FormControl>
                <FormControl>
                    <Button mt={8} type="submit" colorScheme="blue">Submit</Button>
                </FormControl>
            </HStack>
        </form >
    );
}