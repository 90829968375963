import { useState, useEffect } from "react";
import axios from "axios";
import {
    GridItem,
    Grid,
    useToast
} from "@chakra-ui/react";
import SkillForm from "../components/SkillForm";
import { CONFIG } from "../utils/constants";
import React from "react";
import { useSelector } from "react-redux";
import SkillsList from "../components/SkillsList";

export default function Skills() {
    const [skills, setSkills] = useState<any[]>([]);
    const toast = useToast();
    const isAdmin = useSelector((state: any) => state.user.is_admin);
    const token = localStorage.getItem(CONFIG.TOKEN_KEY);
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const fetchData = async () => {
        const result = await axios.get(`${CONFIG.API_URL}/skills`);
        setSkills(result.data.data);
    };

    useEffect(() => {
        fetchData();
    }, []);

    function handleSkillAdd() {
        toast({
            position: 'top-right',
            title: 'Skill added successfully',
            status: 'success',
            duration: 3000,
            isClosable: true
        });
        fetchData();
    }

    function onSkillDelete() {
        fetchData();
    }

    return (
        isAdmin ?
            <Grid py={5} gap={5} templateColumns={'350px 1fr'}>
                <GridItem>
                    <SkillForm onSkillAdd={handleSkillAdd} />
                </GridItem>
                <GridItem>
                    <SkillsList skills={skills} onSkillDelete={onSkillDelete}></SkillsList>
                </GridItem>
            </Grid>
            :
            <SkillsList skills={skills} onSkillDelete={onSkillDelete}></SkillsList>
    );
}