import React, { useState } from 'react';
import {
    FormControl,
    FormLabel,
    Input,
    Button,
    Box,
    Heading,
    useToast,
} from '@chakra-ui/react';
import { CONFIG } from '../utils/constants';
import axios from 'axios';

const ChangePassword = () => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const toast = useToast();
    const token = localStorage.getItem(CONFIG.TOKEN_KEY);
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const handleFormSubmit = async (e: any) => {
        e.preventDefault();
        const postData = {
            current_password: currentPassword,
            new_password: newPassword,
            confirm_new_password: confirmPassword
        }

        try {
            await axios.post(`${CONFIG.API_URL}/user/change_password`, postData);
            toast({
                position: 'top-right',
                title: 'Password changed successfully',
                status: 'success',
                duration: 3000,
                isClosable: true
            });
        } catch (error) {
            let msg = '';
            msg = 'Unable to update password';
            toast({
                position: 'top-right',
                title: `Oops! ${msg}`,
                status: 'error',
                duration: 3000,
                isClosable: true
            });
        }

        setCurrentPassword('');
        setNewPassword('');
        setConfirmPassword('');
    };

    return (
        <Box maxW="400px" mx="auto" my={4} p={4} boxShadow="md" borderRadius="md" bg="white" borderWidth="1px" rounded="lg">
            <Heading mb={4}>Change Password</Heading>

            <form onSubmit={handleFormSubmit}>
                <FormControl mb={4}>
                    <FormLabel>Current Password</FormLabel>
                    <Input
                        type="password"
                        placeholder="Enter current password"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        required
                    />
                </FormControl>

                <FormControl mb={4}>
                    <FormLabel>New Password</FormLabel>
                    <Input
                        type="password"
                        placeholder="Enter new password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                    />
                </FormControl>

                <FormControl mb={4}>
                    <FormLabel>Confirm New Password</FormLabel>
                    <Input
                        type="password"
                        placeholder="Confirm new password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                    />
                </FormControl>

                <Button type="submit" colorScheme="blue">
                    Change Password
        </Button>
            </form>
        </Box>
    );
};

export default ChangePassword;
