import EmployeeCard from "./EmployeeCard";
import { useState, useEffect } from "react";
import axios from "axios";
import { SimpleGrid, Box, Heading, Button, Flex, Spacer, ButtonGroup, Table, TableContainer, Thead, Tr, Th, Tbody, Td, IconButton, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { ViewIcon, CheckIcon, CloseIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { FaGripHorizontal, FaBars } from "react-icons/fa";
import { CONFIG } from "../utils/constants";
import React from "react";
import { useSelector } from "react-redux";

export default function Employees() {
    const [employees, setEmmployees] = useState([]);
    const [viewType, setViewType] = useState("table");
    const isAdmin = useSelector((state: any) => state.user.is_admin);
    const uid = useSelector((state: any) => state.user.uid);
    const navigate = useNavigate();
    const toast = useToast();
    const token = localStorage.getItem(CONFIG.TOKEN_KEY);
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    function addNewEmployee() {
        navigate(`/employee_add`);
    }

    function viewEmployeeDetails(uid: string) {
        navigate(`/employees/${uid}`);
    }

    function editEmployeeDetails(uid: string) {
        navigate(`/employees/${uid}/edit`);
    }

    async function deleteEmployee(uid: string) {
        if (!isAdmin) {
            toast({
                position: 'top-right',
                title: 'Permission Denied!',
                status: 'error',
                duration: 3000,
                isClosable: true
            });
            return;
        }
        try {
            await axios.delete(`${CONFIG.API_URL}/employees/delete/${uid}`);
            toast({
                position: 'top-right',
                title: 'Employee removed successfully',
                status: 'success',
                duration: 3000,
                isClosable: true
            });
            fetchData();
        } catch (error) {
            toast({
                position: 'top-right',
                title: 'Unable to remove employee: ' + error,
                status: 'error',
                duration: 3000,
                isClosable: true
            });
        }
    }

    const fetchData = async () => {
        const result = await axios.get(`${CONFIG.API_URL}/employees`);
        setEmmployees(result.data.data);
    };

    useEffect(() => {
        fetchData();
    }, []);
    return (
        <Box>
            <Flex my={4} minWidth='max-content' alignItems='center' gap='2'>
                <Box p='2'>
                    <Heading size='lg'>Employees</Heading>
                </Box>
                <Spacer />
                {
                    isAdmin &&
                    <ButtonGroup gap='2'>
                        <Button colorScheme='teal' onClick={() => addNewEmployee()}>Add New</Button>
                    </ButtonGroup>
                }
                <ButtonGroup isAttached>
                    <Button bgColor={viewType == 'table' ? 'teal.600' : 'teal.500'} colorScheme="teal" onClick={() => setViewType("table")}><FaBars /></Button>
                    <Button bgColor={viewType == 'cards' ? 'teal.600' : 'teal.500'} colorScheme="teal" onClick={() => setViewType("cards")}><FaGripHorizontal /></Button>
                </ButtonGroup>
            </Flex>
            {
                (viewType == "table") ?
                    <Box
                        boxShadow={'2xl'}
                        padding={4}
                        mb={4}
                        borderWidth="1px"
                        borderRadius="lg"
                        bg='white'>
                        <TableContainer>
                            <Table variant="striped">
                                <Thead>
                                    <Tr>
                                        <Th>#</Th>
                                        <Th>Employee</Th>
                                        <Th>Employee ID</Th>
                                        <Th>Designation</Th>
                                        {isAdmin && <Th>Skills</Th>}
                                        {isAdmin && <Th>Active</Th>}
                                        <Th>Action</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {
                                        employees.map((emp: any, ind: number) => (
                                            <Tr key={emp.uid}>
                                                <Td>{ind + 1}</Td>
                                                <Td>{emp.employee_profile.fullname}</Td>
                                                <Td>{emp.employee_profile.employee_id}</Td>
                                                <Td>{emp.employee_profile.designation.name}</Td>
                                                {isAdmin && <Td>{emp.skills.length}</Td>}
                                                {isAdmin && <Td>{emp.is_active ? <CheckIcon color="green" /> : <CloseIcon color="red" />}</Td>}
                                                <Td>
                                                    <ButtonGroup isAttached={true}>
                                                        <IconButton aria-label="View Employee" colorScheme="teal" icon={<ViewIcon />} onClick={() => viewEmployeeDetails(emp.uid)} />
                                                        {
                                                            (isAdmin || emp.uid == uid) &&
                                                            <IconButton aria-label="Edit Employee" colorScheme="orange" icon={<EditIcon />} onClick={() => editEmployeeDetails(emp.uid)} />
                                                        }
                                                        {
                                                            isAdmin &&
                                                            <IconButton aria-label="Delete Employee" colorScheme="red" icon={<DeleteIcon />} onClick={() => deleteEmployee(emp.uid)} />
                                                        }
                                                    </ButtonGroup>
                                                </Td>
                                            </Tr>
                                        ))
                                    }
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </Box>
                    :
                    <SimpleGrid minChildWidth='250px' spacing={10} py={5}>
                        {
                            employees.map((emp: any) => (
                                <EmployeeCard key={emp.uid} empInfo={emp} onEmployeeDelete={fetchData} />
                            ))
                        }
                    </SimpleGrid>
            }
        </Box >
    );
}