import React from "react";
import { TableContainer, Table, Thead, Tr, Th, Tbody, Td, ButtonGroup, IconButton, Box, useToast } from "@chakra-ui/react";
import { CheckIcon, CloseIcon, EditIcon, DeleteIcon } from "@chakra-ui/icons";
import { useSelector } from "react-redux";
import { CONFIG } from "../utils/constants";
import axios from "axios";

interface EmployeeStatusProps {
    statuses: any[],
    onItemDelete: () => void
}

export default function EmployeeStatusList(props: EmployeeStatusProps) {
    const toast = useToast();
    const isAdmin = useSelector((state: any) => state.user.is_admin);
    const token = localStorage.getItem(CONFIG.TOKEN_KEY);
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    async function deleteItem(itemUid: string) {
        try {
            await axios.delete(`${CONFIG.API_URL}/employee_statuses/delete/${itemUid}`);
            toast({
                position: 'top-right',
                title: 'Employee Status removed successfully',
                status: 'success',
                duration: 3000,
                isClosable: true
            });
            props.onItemDelete();
        } catch (error) {
            toast({
                position: 'top-right',
                title: 'Unable to delete employee status',
                status: 'error',
                duration: 3000,
                isClosable: true
            });
        }
    }
    return (
        <Box
            boxShadow={'2xl'}
            my={4}
            padding={4}
            borderWidth="1px"
            borderRadius="lg"
            bg="white">
            <TableContainer>
                <Table variant="striped">
                    <Thead>
                        <Tr>
                            <Th>Employee Status</Th>
                            <Th>Active</Th>
                            {/* <Th>Employees</Th> */}
                            {isAdmin && <Th>Action</Th>}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {
                            props.statuses.map((d: any) => (
                                <Tr key={d._id}>
                                    <Td>{d.name}</Td>
                                    <Td>{d.is_active ? <CheckIcon /> : <CloseIcon />}</Td>
                                    {/* <Td>{d.employees.length}</Td> */}
                                    <Td>
                                        {
                                            isAdmin &&
                                            <ButtonGroup isAttached>
                                                <IconButton size='xs' aria-label='Edit Status' colorScheme='teal' icon={<EditIcon />} />
                                                <IconButton size='xs' aria-label='Remove Status' colorScheme='red' onClick={() => deleteItem(d.uid)} icon={<DeleteIcon />} />
                                            </ButtonGroup>
                                        }
                                    </Td>
                                </Tr>
                            ))
                        }
                    </Tbody>
                </Table>
            </TableContainer>
        </Box>
    );
}