import { useState, useEffect } from "react";
import axios from "axios";
import { FormControl, FormLabel, Select, Stack, Button, Input, Box, Flex, Heading, Spacer, Divider, HStack, useToast, Checkbox } from "@chakra-ui/react";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { CONFIG } from "../utils/constants";
import React from "react";
import { useSelector } from "react-redux";

export default function EditEmployeeForm() {
    let { uid } = useParams();
    const isAdmin = useSelector((state: any) => state.user.is_admin);
    const loggedInUserUid = useSelector((state: any) => state.user.uid);

    const [designations, setDesignations] = useState([]);
    const [statuses, setStatuses] = useState([]);

    const [employee, setEmployee] = useState<any>({
        employee_id: '',
        tl_id: '',
        firstname: '',
        lastname: '',
        work_email: '',
        mobile: '',
        designation: '',
        joining_date: moment().format("YYYY-MM-DD"),
        leave_date: '',
        status: '',
        is_active: false,
        jira_id: '',
        tl_email: '',
    });

    const toast = useToast();
    const navigate = useNavigate();

    const fetchData = async () => {
        if (uid != loggedInUserUid && !isAdmin) {
            toast({
                position: 'top-right',
                title: 'Permission Denied! This incident will be reported to admin',
                status: 'error',
                duration: 3000,
                isClosable: true
            });

            navigate("/employees");
            return;
        }
        const designations = await axios.get(`${CONFIG.API_URL}/designations`);
        const statuses = await axios.get(`${CONFIG.API_URL}/employee_statuses`);
        const employeeDetails = await axios.get(`${CONFIG.API_URL}/employees/details/${uid}`);

        setDesignations(designations.data.data);
        setStatuses(statuses.data.data);

        const emp: any = employeeDetails.data.data.employee_profile;
        const empProfile: any = employeeDetails.data.data.employee_profile;
        setEmployee({
            employee_id: empProfile.employee_id,
            tl_id: empProfile.tl_id,
            firstname: empProfile.firstname,
            lastname: empProfile.lastname,
            work_email: empProfile.work_email,
            mobile: empProfile.mobile,
            designation: empProfile.designation._id,
            joining_date: empProfile.joining_date ? moment(empProfile.joining_date * 1000).format("YYYY-MM-DD") : '',
            leave_date: empProfile.leave_date ? moment(empProfile.leave_date * 1000).format("YYYY-MM-DD") : '',
            status: empProfile.status._id,
            is_active: emp.is_active,
            jira_id: empProfile.jira_id || null,
            tl_email: empProfile.tl_email || null
        });
    };
    
    useEffect(() => {
        fetchData();
    }, []);
    
    const handleInputChange = (event: any) => {
        const target = event.target;
        console.log("setemployee et: ", target.name, target.checked);
        setEmployee({
            ...employee,
            [target.name]: (target.checked != undefined) ? target.checked : target.value
        });
        console.log("setemployee: ", employee);
    }

    const handleFormSubmit = async (event: any) => {
        event.preventDefault();
        try {
            await axios.post(`${CONFIG.API_URL}/employees/update/${uid}`, employee);
            navigate("/employees");
            toast({
                position: 'top-right',
                title: 'Details updated successfully',
                status: 'success',
                duration: 3000,
                isClosable: true
            });
        } catch (error) {
            console.log("Save Error: ", error);
            toast({
                position: 'top-right',
                title: 'Unable to update: ' + error,
                status: 'error',
                duration: 3000,
                isClosable: true
            });
        }
    }

    return (
        <Box
            my={4}
            px={6}
            py={2}
            backgroundColor="white">
            <Flex my={2} minWidth='max-content' alignItems='center' gap='2'>
                <Box p='2'>
                    <Heading size='lg'>Edit Employee</Heading>
                </Box>
                <Spacer />
            </Flex>
            <Divider mb={2} />
            <form onSubmit={handleFormSubmit}>
                <HStack spacing={4} mb={2}>
                    <FormControl>
                        <FormLabel>Employee ID</FormLabel>
                        <Input type="text" name="employee_id" placeholder="SST/IT/SD/XXX" value={employee.employee_id} readOnly={!isAdmin} disabled={!isAdmin} onChange={handleInputChange}></Input>
                    </FormControl>
                    {
                        isAdmin &&
                        <FormControl isRequired>
                            <FormLabel>TL ID</FormLabel>
                            <Input type="text" name="tl_id" placeholder="TL ID" value={employee.tl_id} onChange={handleInputChange}></Input>
                        </FormControl>
                    }
                </HStack>
                <HStack spacing={4} mb={2}>
                    <FormControl isRequired>
                        <FormLabel>Firstname</FormLabel>
                        <Input type="text" name="firstname" placeholder="First name" value={employee.firstname} onChange={handleInputChange}></Input>
                    </FormControl>
                    <FormControl isRequired>
                        <FormLabel>Lastname</FormLabel>
                        <Input type="text" name="lastname" placeholder="Last name" value={employee.lastname} onChange={handleInputChange}></Input>
                    </FormControl>
                </HStack>
                <HStack spacing={4} mb={2}>
                    <FormControl>
                        <FormLabel>Work Email</FormLabel>
                        <Input type="text" name="work_email" placeholder="Work Email" value={employee.work_email} readOnly={!isAdmin} disabled={!isAdmin}></Input>
                    </FormControl>
                    <FormControl isRequired>
                        <FormLabel>Mobile</FormLabel>
                        <Input type="text" name="mobile" placeholder="Mobile" value={employee.mobile} onChange={handleInputChange}></Input>
                    </FormControl>
                </HStack>
                <HStack spacing={4} mb={2}>
                    <FormControl isRequired>
                        <FormLabel>Joining Date</FormLabel>
                        <Input type="date" name="joining_date" placeholder="Joining Date" value={employee.joining_date} onChange={handleInputChange}></Input>
                    </FormControl>
                    {
                        isAdmin &&
                        <FormControl>
                            <FormLabel>Leave Date</FormLabel>
                            <Input type="date" name="leave_date" placeholder="Leave Date" value={employee.leave_date} onChange={handleInputChange}></Input>
                        </FormControl>
                    }
                </HStack>
                <HStack spacing={4} mb={2}>
                    <FormControl isRequired>
                        <FormLabel>Designation</FormLabel>
                        <Select placeholder='Select Designation' name="designation" value={employee.designation} onChange={handleInputChange} isReadOnly={!isAdmin} disabled={!isAdmin}>
                            {
                                designations.map((d: any) => (
                                    <option key={d._id} value={d._id}>{d.name}</option>
                                ))
                            }
                        </Select>
                    </FormControl>
                    {
                        isAdmin &&
                        <FormControl isRequired>
                            <FormLabel>Status</FormLabel>
                            <Select placeholder='Select Status' name="status" value={employee.status} onChange={handleInputChange}>
                                {
                                    statuses.map((s: any) => (
                                        <option key={s._id} value={s._id}>{s.name}</option>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    }
                </HStack>
                {
                    isAdmin &&
                    <HStack spacing={4} mb={2}>
                        <FormControl>
                            <FormLabel>Jira ID</FormLabel>
                            <Input type="text" name="Jira ID" placeholder="Jira ID" value={employee.jira_id} onChange={handleInputChange}></Input>
                        </FormControl>
                        <FormControl>
                            <FormLabel>TL Email</FormLabel>
                            <Input type="text" name="tl_email" placeholder="TL Email" value={employee.tl_email} onChange={handleInputChange}></Input>
                        </FormControl>
                    </HStack>
                }
                {
                    isAdmin &&
                    <HStack spacing={4} my={4}>
                        <FormControl>
                            <Checkbox name="is_active" defaultChecked={employee.is_active} onChange={handleInputChange}>Is Active</Checkbox>
                        </FormControl>
                    </HStack>

                }
                <Stack>
                    <Button my={4} type="submit" colorScheme="blue" size="md">Update</Button>
                </Stack>
            </form>
        </Box>
    );
}