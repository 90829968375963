import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    Stack,
    Button,
    Heading,
    useColorModeValue,
    useToast,
    InputGroup,
    InputRightElement,
    IconButton,
    Image,
} from '@chakra-ui/react';
import { useState } from "react";
import axios, { AxiosError } from "axios";
import { useNavigate } from 'react-router-dom';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import jwtDecode from 'jwt-decode';
import { CONFIG } from '../../utils/constants';
import React from 'react';
import { useDispatch } from 'react-redux';
import { setAdmin, setUid, setRoles, setProfile } from "../../stores/ProfileSlice";
export default function Signin() {
    const toast = useToast();
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordVisibility, setPasswordVisibility] = useState(false);

    const dispatch = useDispatch();

    const handleFormSubmit = async (event: any) => {
        event.preventDefault();
        const postObj = {
            email,
            password
        };
        try {
            const result = await axios.post(`${CONFIG.API_URL}/login`, postObj);
            setEmail("");
            setPassword("");

            if (result.data.data?.token) {
                localStorage.setItem(CONFIG.TOKEN_KEY, result.data.data.token);

                const token = result.data.data.token;

                // set token as default header on all axios request now
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

                let profileData: any = {};

                try {
                    // get logged in user profile
                    const profileResult = await axios.get(`${CONFIG.API_URL}/user/profile`);

                    if (profileResult.data.data) {
                        profileData = profileResult.data.data;
                    }
                } catch (error) {
                    // console.log("Unable to fetch user profile", error);
                }
                // set logged in status, roles and profile
                dispatch(setUid(profileData.uid));
                dispatch(setProfile(profileData));

                // decode token to get role
                const decoded: any = jwtDecode(token);
                dispatch(setRoles(decoded.roles));

                if (decoded.roles.includes('ADMIN')) {
                    dispatch(setAdmin(true));
                } else {
                    dispatch(setAdmin(false));
                }

                navigate("/");
                toast({
                    position: 'top-right',
                    title: 'Login successful',
                    status: 'success',
                    duration: 3000,
                    isClosable: true
                });
            } else {
                toast({
                    position: 'top-right',
                    title: 'Login Failed!',
                    status: 'error',
                    duration: 3000,
                    isClosable: true
                });
            }
        } catch (error) {
            if (error instanceof AxiosError) {
                toast({
                    position: 'top-right',
                    title: error.response ? error.response.data.message : error.message,
                    status: 'error',
                    duration: 3000,
                    isClosable: true
                });
            } else {
                toast({
                    position: 'top-right',
                    title: "Oops! Unable to signin. Please try again",
                    status: 'error',
                    duration: 3000,
                    isClosable: true
                });
            }
        }
    }

    return (
        <Flex minH={'100vh'} align={'center'} justify={'center'} bg={useColorModeValue('gray.50', 'gray.800')}>
            <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
                <Stack align={'center'}>
                    <Image my={4} src="/SSPL_Full_Logo_Horizontal.svg" width="250px" />
                </Stack>
                <Box rounded={'lg'} bg={useColorModeValue('white', 'gray.700')} boxShadow={'lg'} p={8} minW={400}>
                    <Stack spacing={4}>
                        <form onSubmit={handleFormSubmit}>
                            <FormControl id="email" isRequired>
                                <FormLabel>Email address</FormLabel>
                                <Input type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} autoComplete="email" placeholder="john.doe@sadhrasya.com" />
                            </FormControl>
                            <FormControl id="password" isRequired>
                                <FormLabel>Password</FormLabel>
                                <InputGroup>
                                    <Input type={passwordVisibility ? "text" : "password"} name="password" value={password} onChange={(e) => setPassword(e.target.value)} autoComplete="current-password" />
                                    <InputRightElement>
                                        <IconButton aria-label="Password Visibility" icon={(passwordVisibility) ? <ViewOffIcon /> : <ViewIcon />} onClick={() => setPasswordVisibility(!passwordVisibility)} />
                                    </InputRightElement>
                                </InputGroup>
                            </FormControl>
                            <Stack mt={4} spacing={10}>
                                <Button type="submit" bg={'blue.400'} color={'white'}
                                    _hover={{
                                        bg: 'blue.500',
                                    }}>
                                    Sign in
                            </Button>
                            </Stack>
                        </form>
                    </Stack>
                </Box>
            </Stack>
        </Flex>
    );
}